import React from 'react';
import './App.css';
// import logo from './img/kobakha.png'

function App() {
  return (
    <div className="App">
      <header className="App-header">
        {/* <img src={logo} alt="" /> */}
        <h1>ena miiigde dzondzis kukla</h1>
      </header>
    </div>
  );
}

export default App;
